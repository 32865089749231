import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "@fortawesome/fontawesome-free/css/all.css";
import "./assets/css/style.css";
import "./assets/css/Media.css";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { GoogleOAuthProvider } from '@react-oauth/google';

import Login from "./pages/Login";
import Profile from "./pages/Profile";
import Home from "./pages/Home";
import { Footer } from "./component/Footer";
import { Header } from "./component/Header";
import Session from "./component/Session";
import ChangePassword from "./pages/ChangePassword.js";
import ResetPassword from "./pages/ResetPassword.js"; // Import ResetPassword page
import ForgotPassword from "./pages/ForgotPassword.js";
import About from "./pages/About.js";
import TermOfService from "./pages/TermOfService.js";
import MyTeam from "./pages/MyTeam.js";
import Deposit from "./pages/Deposit.js";
import InvestmentPlan from "./pages/InvestmentPlan.js";
import Contact_Us from "./pages/ContactUs.js";
import Blog from "./pages/Blog.js";
import BlogDetail from "./pages/blog-detail.js";
import PrivacyPolice from "./pages/PrivacyPolice.js";
import Withdrawal from "./pages/Withdrawal.js";
import WithdrawalHistory from "./pages/WithdrawalHistory.js";

function App() {
  const user = Session.getSession('user');

 
  const isResetPasswordRoute = window.location.pathname.startsWith("/reset-password");

  return (
    <div className={`${!isResetPasswordRoute ? 'App top-space' : 'App'}`}>
      <GoogleOAuthProvider clientId="304980111754-prmb751a6u2upksl6n9b2mpb308kdcd1.apps.googleusercontent.com">
        <BrowserRouter>
          {/* Conditionally render Header except for reset-password route */}
          {!isResetPasswordRoute && <Header />}

         <Routes>
            <Route exact path="/login" element={<Login />} />
            <Route exact path="/withdrawal-history" element={<WithdrawalHistory />} />

            <Route exact path="/" element={<Home />} />
            <Route exact path="/investment-plan" element={<InvestmentPlan />} />
            <Route exact path="/contact-us" element={<Contact_Us />} />
            <Route exact path="/blog" element={<Blog />} />
            <Route exact path="/blog-detail" element={<BlogDetail />} />
            <Route exact path="/privacypolice" element={<PrivacyPolice/>} />
            <Route exact path="/withdrawal" element={<Withdrawal/>} />

            <Route exact path="/about" element={<About />} />
            <Route exact path="/term_of_service" element={<TermOfService />} />

            {/* {user && */}
              <>
                <Route exact path="/my-team" element={<MyTeam />} />
                <Route exact path="/deposit" element={<Deposit />} />
                <Route exact path="/profile" element={<Profile />} />
              </>
            {/* } */}

            <Route exact path="/change-password" element={<ChangePassword />} />
            <Route exact path="/forgot-password" element={<ForgotPassword />} />

            {/* Define reset-password route without Header and Footer */}
            <Route path="/reset-password/:token" element={<ResetPassword />} />
            <Route path="*" element={<Navigate to="/" />} />
         </Routes>

          {/* Conditionally render Footer except for reset-password route */}
          {!isResetPasswordRoute && <Footer />}
        </BrowserRouter>
      </GoogleOAuthProvider>
    </div>
  );
}

export default App;
